/*

Javascript FrontEnd.

 */

//$(document).ready(function (){

    updSlider();

    function updSlider() {
        if ($('.slider').length) {
            $('.slider').responsiveSlides({
                nav: true,
                prevText: '',
                nextText: '',
                timeout: 5000,
                speed: 500,
                pause: true,
            });
        }
    }


    function updMarcadorCompra( treu ){

        if (treu) {
            $('#carrople').hide();
            $('#carroplexs').hide();
            $('#carrobuid').show();
            return;
        }

        $.get('/ajax/numItems',function (data){

            if (data==0){
                $('#carrople').hide();
                $('#carroplexs').hide();
                $('#carrobuid').show();

            }else{
                $('#carrople').show();
                $('#carroplexs').show();
                $('#carrobuid').hide();
                $('#numItems').html( data );
                $('#numItemsxs').html( data );
            }
        });
    }

    function putSliderNavArrows(){
        //$('a.rslides_nav.next').css('left', $('a.rslides_nav.prev').offset().left + $('.slider').width() - $('a.rslides_nav.next').width());
    }

    $(window).on('resize', function(){
        //putSliderNavArrows();
    });

    $('.close-modal').click(function (e){
        e.preventDefault();
        $('.modal').hide();
    })

    $('.forgot-password').click(function (e){
        e.preventDefault();
        $('#login').hide();
        $($(this).attr('href')).show();
    })

    //info enviament
    $('#mostraCostEnviament').click(function (e){
        e.preventDefault();
        $('#modalCostEnviament').show();
    })


    $( "#formMailChimp" ).submit(function( event ) {
        $.ajax({
            url: "/joinNews",
            data: {email: $('#newsletter-mail').val() },
            success: function (r) {
                if (r='ok'){
                    $('#newsletter-mail').val('');
                    $('#modalsubscripcio').show();
                } else if (r='exists'){
                    $('#newsletter-mail').val('');
                    $('#modalsubscripcio2').show();
                } else {
                    alert('error!');
                }
            }
        });
        event.preventDefault();
    });


$( "#heLlegit" ).change(function() {
    if ($(this).is(':checked') ){
        //$('#desaCanvisClient').removeAttr('disabled');
        $('#desaCanvisClientFake').hide();
        $('#desaCanvisClient').show();}
    else
       { //$('#desaCanvisClient').attr('disabled','disabled');
        $('#desaCanvisClientFake').show();
        $('#desaCanvisClient').hide();
    }
});

$( "#ferFactura" ).change(function() {
    $("#facturacio").toggle();
});


$( ".btnCopyAdr" ).click(function() {

    $('input[name=f_adreca]').val($('input[name=adreca]').val());
    $('input[name=f_cpostal]').val($('input[name=cpostal]').val());
    $('input[name=f_poblacio]').val($('input[name=poblacio]').val());
    $('select[name=f_gen_provincies_id]').val($('select[name=gen_provincies_id] option:selected').val());
    $('select[name=f_gen_paisos_id]' ).val($('select[name=gen_paisos_id] option:selected' ).val());

});


//PAISOS i PROVINCIES

if($("#triaPaisF").length > 0 && $("#triaPaisF").val()==68) {
    $("#triaProvinciaF").show();
}else{
    $("#triaProvinciaF").hide();
}

if($("#triaPais").length > 0 && $("#triaPais").val()==68) {
    $("#triaProvincia").show();
}else {
    $("#triaProvincia").hide();
}


$( "#triaPaisF" ).change(function() {
    // Check input( $( this ).val() ) for validity here
    if ($(this).val() == 68) {
        $("#triaProvinciaF").show();
    }else{
        $("#triaProvinciaF").hide();
    };
});


$( "#triaPais" ).change(function() {
    // Check input( $( this ).val() ) for validity here
    if ($(this).val() == 68) {
        $("#triaProvincia").show();
    }else{
        $("#triaProvincia").hide();
    };
});




$('#desaCanvisClientFake').click(function (){
    $("#modalTermes").show();
})

$('#open-menu').click(function (e){
    e.preventDefault();
    $('.menu-mobile').slideToggle();
})


function entornBootstrap() {
    var envs = ['xs', 'sm', 'md', 'lg'];

    $el = $('<div>');
    $el.appendTo($('body'));

    for (var i = envs.length - 1; i >= 0; i--) {
        var env = envs[i];

        $el.addClass('hidden-'+env);
        if ($el.is(':hidden')) {
            $el.remove();
            return env
        }
    };
}


//});

